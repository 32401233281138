import PositionJobPostingDto from '@domain/rs/position/jd/PositionJobPostingDto';

class PositionJobPostingVO {
  // 채용공고 sn
  jobPostingSn: number;
  // 채용공고명
  jobPostingName: string;
  // 공고 내 포지션 수
  positionCount: number;

  constructor(dto: PositionJobPostingDto) {
    this.jobPostingSn = dto.jobPostingSn;
    this.jobPostingName = dto.jobPostingName;
    this.positionCount = dto.positionCount;
  }
}

export default PositionJobPostingVO;
