import PositionTextListItemVO from '@domain/vo/common/PositionTextListItemVO';
import { PositionCompanyCardListRs } from '@domain/rs/position/jd/PositionCompanyCardListRs';

export class PositionCompanyCardListVO {
  positions: PositionTextListItemVO[] | null = null;

  constructor(rs: PositionCompanyCardListRs) {
    this.positions = rs.positions.map((dto) => {
      return new PositionTextListItemVO({
        ...dto,
      });
    });
  }
}
