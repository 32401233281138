import PositionJobPostingVO from '@domain/vo/common/PositionJobPostingVO';
import PositionJobPostingDto from '@domain/rs/position/jd/PositionJobPostingDto';
import { RecruitCategoryTypeNameMap, RecruitCategoryType } from '@domain/constant/recruitCategoryType';
import { V2Route } from '@utils/routeUtil';
import NumberFormat from '@utils/number/format';
import DateFormat from '@utils/date/format';

export interface IPositionTextListItemVO {
  positionSn: number;
  positionName: string;
  companyName: string;

  recruitmentType: RecruitCategoryType;
  locationCode: number;
  jobGroupName: string;
  jobTitleNames: string[];
  closingDateTime: string;

  minSalary: number | null;
  maxSalary: number | null;
  jobPosting: PositionJobPostingDto | null;
}

export default class PositionTextListItemVO {
  positionSn: number;
  positionName: string;

  companyName: string;
  routeUrl: string; //클릭시 이동할 페이지

  //하단 태그 영역
  recruitmentTypeText: string;
  locationName: string;

  jobGroupName: string;
  jobTitleNames: string;
  closingText: string;
  salary: string;

  subInfoTextList: string[];
  jobPosting: PositionJobPostingVO | null;

  constructor(vo: IPositionTextListItemVO) {
    this.positionSn = vo.positionSn;
    this.positionName = vo.positionName;

    this.recruitmentTypeText = this.getTagText(RecruitCategoryTypeNameMap[vo.recruitmentType]);
    this.closingText = DateFormat.formatPositionClosingDateText(vo.closingDateTime);
    this.locationName = NumberFormat.formatLocationName(vo.locationCode);
    this.companyName = vo.companyName;
    this.routeUrl = V2Route.getPositionJDPage(vo.positionSn);
    this.jobGroupName = this.getTagText(vo.jobGroupName);
    this.jobTitleNames = this.getJobTitleNameText(vo.jobTitleNames);
    this.salary = NumberFormat.formatPositionSalaryText(vo.minSalary, vo.maxSalary);

    this.subInfoTextList = this.getSubInfoTextList();
    this.jobPosting = vo.jobPosting;
  }

  private getTagText(tag?: string) {
    if (!tag) return '';
    return `#${tag}`;
  }

  private getJobTitleNameText(jobTitleNames: string[]) {
    if (jobTitleNames.length < 1) return '';
    if (jobTitleNames.length === 1) return `#${jobTitleNames[0]}`;

    return `#${jobTitleNames[0]}+${jobTitleNames.length - 1}`;
  }
  private getSubInfoTextList() {
    if (this.jobTitleNames.length)
      return [
        [this.recruitmentTypeText, this.jobTitleNames, this.closingText].join('   '), //line1
        this.salary, //line2
      ];

    return [
      [this.recruitmentTypeText, this.jobGroupName, this.closingText].join('   '), //line1
      this.salary, //line2
    ];
  }
}
