import PositionTextListItemVO from '@domain/vo/common/PositionTextListItemVO';
import { PositionChallengeCardListRs } from '@domain/rs/position/jd/PositionChallengeCardListRs';

export class PositionChallengeCardListVO {
  positions: PositionTextListItemVO[] | null = null;

  constructor(rs: PositionChallengeCardListRs) {
    this.positions = rs.positions.map((dto) => {
      return new PositionTextListItemVO({
        ...dto,
      });
    });
  }
}
