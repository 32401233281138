import { PositionBookmarkListItemVO } from '@domain/vo/common/PositionBookmarkListItemVO';
import { PositionJobListCardRs } from '@domain/rs/position/jd/PositionJobListCardRs';

export class PositionJobListCardVO {
  positions: PositionBookmarkListItemVO[] | null = null;

  constructor(rs: PositionJobListCardRs) {
    this.positions = rs.positions.map((dto) => {
      return new PositionBookmarkListItemVO({
        ...dto,
        sn: dto.positionSn,
        title: dto.positionName,
      });
    });
  }
}
