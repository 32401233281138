import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import PositionListFilterRq from '@domain/rq/position/list/PositionListFilterRq';
import PositionJobGroupsRq from '@domain/rq/position/PositionJobGroupsRq';
import PositionProfileRecommendRq from '@domain/rq/index/PositionProfileMatchingRq';
import { PositionDetailRs } from '@domain/rs/position/jd/PositionDetailRs';
import { PositionCompanyCardListRs } from '@domain/rs/position/jd/PositionCompanyCardListRs';
import { PositionJobListCardRs } from '@domain/rs/position/jd/PositionJobListCardRs';
import { PositionBookmarkRs } from '@domain/rs/position/jd/PositionBookmarkRs';
import { PositionChallengeCardListRs } from '@domain/rs/position/jd/PositionChallengeCardListRs';
import PositionListRs from '@domain/rs/position/list/PositionListRs';
import { PositionMetadataRs } from '@domain/rs/position/jd/PositionMetadataRs';
import { PositionJobGroupsRs } from '@domain/rs/position/PositionJobGroupsRs';
import { PositionRecommendRs } from '@domain/rs/index/PositionJobListCardRs';

export default interface PositionRepo {
  //전체 포지션 목록 조회 (필터, 정렬, 검색)
  fetchPositionList(rq: PositionListFilterRq): Promise<AxiosResponse<PositionListRs>>;

  // 매칭 포지션 JD 상세 조회
  fetchJD(positionSn: number): Promise<AxiosResponse<PositionDetailRs>>;

  // 매칭 포지션 JD 미리보기 조회
  fetchJDPreview(publicKey: string, positionId: string): Promise<AxiosResponse<PositionDetailRs>>;

  // 포지션 북마크 추가
  fetchAddBookmark(positionSn: number): Promise<AxiosResponse<PositionBookmarkRs>>;

  // 포지션 북마크 삭제
  fetchDeleteBookmark(positionSn: number): Promise<AxiosResponse<boolean>>;

  // 동일 기업 포지션 조회
  fetchMatchingCompany(positionSn: number): Promise<AxiosResponse<PositionCompanyCardListRs>>;

  // 동일 직군 포지션 랜덤 조회
  fetchMatchingJob(positionSn: number, pageSize: number): Promise<AxiosResponse<PositionJobListCardRs>>;

  // 동일 챌린지 포지션 조회
  fetchMatchingChallenge(positionSn: number): Promise<AxiosResponse<PositionChallengeCardListRs>>;

  //포지션 상세 URL 조회(빌더)
  fetchPositionSn(publickKey: string, positionId: number): Promise<AxiosResponse<number>>;

  // 포지션 메타데이터 조회 (SEO)
  fetchPositionMetadata(positionSn: number): Promise<AxiosResponse<PositionMetadataRs>>;

  // 직무 기반 맞춤 포지션 조회
  fetchPositionProfileRecommend(rq: PositionProfileRecommendRq): Promise<AxiosResponse<PositionRecommendRs>>;

  //직군별 인기 포지션 조회 > 선택 직군 포지션 랜덤 조회
  fetchPositionJobGroups(rq: PositionJobGroupsRq): Promise<AxiosResponse<PositionJobGroupsRs>>;
}

export class RemotePositionRepo implements PositionRepo {
  fetchPositionList(rq: PositionListFilterRq) {
    return axios.get<PositionListRs>(`/position`, {
      params: rq,
    });
  }

  fetchJD(positionSn: number): Promise<AxiosResponse<PositionDetailRs>> {
    return axios.get(`/v2/position/${positionSn}`);
  }

  fetchJDPreview(publicKey: string, positionId: string): Promise<AxiosResponse<PositionDetailRs>> {
    return axios.get(`/position/preview`, {
      params: { publicKey, positionId },
    });
  }

  fetchAddBookmark(positionSn: number): Promise<AxiosResponse<PositionBookmarkRs>> {
    return axios.put(`/position/${positionSn}/bookmark`);
  }

  fetchDeleteBookmark(positionSn: number): Promise<AxiosResponse<boolean>> {
    return axios.delete(`/position/${positionSn}/bookmark`);
  }

  fetchMatchingCompany(positionSn: number): Promise<AxiosResponse<PositionCompanyCardListRs>> {
    return axios.get(`/position/${positionSn}/matching-company`);
  }

  fetchMatchingJob(positionSn: number, pageSize: number): Promise<AxiosResponse<PositionJobListCardRs>> {
    return axios.get(`/position/${positionSn}/matching-job`, { params: { pageSize } });
  }

  fetchMatchingChallenge(positionSn: number): Promise<AxiosResponse<PositionChallengeCardListRs>> {
    return axios.get(`/position/${positionSn}/matching-challenge`);
  }

  fetchPositionSn(publicKey: string, positionId: number): Promise<AxiosResponse<number>> {
    return axios.get(`/position/sn`, { params: { publicKey, positionId } });
  }

  fetchPositionMetadata(positionSn: number) {
    return axios.get(`/position/${positionSn}/metadata`);
  }

  fetchPositionProfileRecommend(rq: PositionProfileRecommendRq): Promise<AxiosResponse<PositionRecommendRs>> {
    return axios.get('/position/profile/recommend', { params: rq });
  }

  fetchPositionJobGroups(rq: PositionJobGroupsRq) {
    return axios.get<PositionJobGroupsRs>('/position/job-groups', {
      params: rq,
    });
  }
}
