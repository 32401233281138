import { PositionDetailCompanyDto } from '@domain/rs/position/jd/PositionDetailCompanyDto';
import { businessSizeTextMap, BusinessSizeType } from '@domain/constant/businessSize';
import { LocationCodeNumberType } from '@domain/constant/locationType';
import NumberFormat from '@utils/number/format';
import DateFormat from '@utils/date/format';

export class PositionDetailCompanyVO {
  companySn: number;
  companyName: string;
  homepageUrl: string;
  ceoName: string;
  startingSalary: number;
  startingSalaryText: string;
  averageSalary: number;
  averageSalaryText: string;
  numberOfEmployees: number;
  numberOfEmployeesText: string;
  businessSize: BusinessSizeType;
  businessSizeText: string;
  establishDate: string;
  establishDateText: string;

  locationCode: LocationCodeNumberType;
  location: string;
  revenue: number;
  revenueText: string;
  openYn: boolean;
  logoImageUrl: string;
  lastModifiedDateText: string;

  constructor(dto: PositionDetailCompanyDto) {
    this.companySn = dto.companySn;
    this.companyName = dto.companyName;
    this.homepageUrl = dto.homepageUrl;
    this.ceoName = dto.ceoName ? dto.ceoName : '-';
    //입사자 평균연봉 원단위 제거
    this.startingSalary = dto.startingSalary ? Math.floor(dto.startingSalary / 10000) * 10000 : 0;
    this.startingSalaryText = this.startingSalary ? NumberFormat.format(this.startingSalary, 'ALL', ' ') : '-';
    this.averageSalary = dto.averageSalary ? Math.floor(dto.averageSalary / 10000) * 10000 : 0;
    this.averageSalaryText = this.averageSalary ? NumberFormat.format(this.averageSalary, 'ALL', ' ') : '-';
    this.numberOfEmployees = dto.numberOfEmployees;
    this.numberOfEmployeesText = dto.numberOfEmployees ? NumberFormat.formatDecimal(dto.numberOfEmployees) : '-';
    this.businessSize = dto.businessSize;
    this.businessSizeText = dto.businessSize ? businessSizeTextMap[dto.businessSize] : '-';
    this.establishDate = dto.establishDate;
    this.establishDateText = dto.establishDate
      ? DateFormat.format(new Date(dto.establishDate), 'yyyy년 MM월 dd일')
      : '-';
    this.locationCode = dto.locationCode;
    this.location = dto.location ? dto.location : '-';
    //매출액 만원 단위
    this.revenue = dto.revenue;
    this.revenueText = NumberFormat.formatCompanyRevenueAndProfitText(dto.revenue);
    this.openYn = dto.openYn;
    this.logoImageUrl = dto.logoImageUrl;
    this.lastModifiedDateText = this.getDateFormat(dto.lastModifiedDate);
  }

  getDateFormat(data: string | null) {
    return data ? `업데이트 일자: ${DateFormat.format(new Date(data), 'yyyy.MM.dd')}` : '';
  }
}
