import { Descendant } from 'slate';
import { PositionAttachFileDto } from '@domain/rs/position/jd/PositionAttachFileDto';
import { PositionDetailBasicDto } from '@domain/rs/position/jd/PositionDetailBasicDto';
import { PositionApplyEnum, PositionApplyType } from '@domain/constant/PositionApplyType';
import { RecruitCategoryType, RecruitCategoryTypeNameMap } from '@domain/constant/recruitCategoryType';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';
import NumberFormat from '@utils/number/format';
import TextFormat from '@utils/text/format';

export class PositionDetailBasicVO {
  positionSn: number;
  positionName: string;
  recruitmentType: RecruitCategoryType;
  recruitmentTypeText: string;
  address: string | null;
  detailAddress: string | null;
  minSalary: number | null;
  maxSalary: number | null;
  salaryText: string;
  views: number;
  createdDateTimeISO: string;
  createdDateTime: string;
  closingDateTime: string | null;
  openingDateTime: string | null;
  jobDescription: Descendant[];
  jobflexDescription: string;
  averageReplyDays: number | null;
  images: PositionAttachFileVO[] | null;
  openYn: boolean;
  externalYn: boolean; //잡플렉스 공고 관련
  applyType: PositionApplyType;
  recruitmentUrl: string;
  jobDescriptionImageUrl: string | null;
  terminatePosition: boolean;
  isApplicable: boolean;

  constructor(dto: PositionDetailBasicDto) {
    this.positionSn = dto.positionSn;
    this.positionName = dto.positionName;
    this.recruitmentType = dto.recruitmentType;
    this.recruitmentTypeText = RecruitCategoryTypeNameMap[dto.recruitmentType];

    this.address = dto.address;
    this.detailAddress = dto.detailAddress;
    this.minSalary = dto.minSalary;
    this.maxSalary = dto.maxSalary;
    this.salaryText = NumberFormat.formatPositionSalaryText(dto.minSalary, dto.maxSalary);
    this.views = dto.views;
    this.createdDateTimeISO = dto.createdDateTime;
    this.createdDateTime = this.formatCreatedDateTime(dto.createdDateTime);
    this.closingDateTime = dto.closingDateTime ? this.formatCreatedDateTime(dto.closingDateTime) : null;
    this.openingDateTime = dto.openingDateTime ? this.formatCreatedDateTime(dto.openingDateTime) : null;
    this.jobDescription = this.setJobDescription(dto.jobDescription);
    this.jobflexDescription = dto.jobDescription;
    this.averageReplyDays = dto.averageReplyDays;
    this.images = dto.images?.map((item) => new PositionAttachFileVO(item)) ?? null;
    this.openYn = dto.openYn;
    this.externalYn = dto.externalYn;
    this.applyType = PositionApplyEnum[dto.applyType];
    this.recruitmentUrl = this.formatSiteUrl(dto.recruitmentUrl);
    this.jobDescriptionImageUrl = dto.jobDescriptionImageUrl;
    this.terminatePosition =
      !dto.openYn || (dto.closingDateTime ? DateUtil.isPastDate(new Date(dto.closingDateTime)) : false);
    this.isApplicable = dto.isApplicable;
  }

  setJobDescription(data: string): Descendant[] {
    try {
      return JSON.parse(data);
    } catch (e) {
      return [];
    }
  }

  private formatCreatedDateTime(createdDate: string) {
    return DateFormat.format(new Date(createdDate), 'yyyy.MM.dd(week) HH:mm');
  }

  private formatSiteUrl(url: string | null) {
    return url ? TextFormat.formatProtocol(url) : '';
  }
}

export class PositionAttachFileVO {
  caption: string;
  description: string;
  fileUid: string;
  fileUrl: string;
  priority: number;

  constructor(dto: PositionAttachFileDto) {
    this.caption = dto.caption;
    this.description = dto.description;
    this.fileUid = dto.fileUid;
    this.fileUrl = dto.fileUrl;
    this.priority = dto.priority;
  }
}

export class PositionEndInfoVO {
  type: 'always' | 'period';
  openYn: boolean;
  closeYn: boolean;
  dDay: string | null;
  closingDateTime: string | null;
  leftDays: number | null;

  constructor(closingDateTime: string | null, openYn: boolean) {
    this.type = closingDateTime === null ? 'always' : 'period';
    this.openYn = openYn;
    this.closeYn = this.getCloseYn(closingDateTime);
    this.dDay = this.getDDay(closingDateTime, this.closeYn);
    this.closingDateTime = this.getEndDateTime(closingDateTime);
    this.leftDays = this.getLeftDays(closingDateTime);
  }

  private getCloseYn(closingDateTime: string | null) {
    if (!closingDateTime) return false;
    return DateUtil.isPastDate(new Date(closingDateTime));
  }

  private getLeftDays(closingDateTime: string | null) {
    if (!closingDateTime) return null;
    return DateUtil.getLeftDays(DateUtil.getNow(), new Date(closingDateTime));
  }

  private getDDay(closingDateTime: string | null, closeYn: boolean) {
    //상시거나 마감된 공고
    if (!closingDateTime || closeYn) return null;
    const leftDays = DateUtil.getLeftDays(DateUtil.getNow(), new Date(closingDateTime));
    if (leftDays === 0) return 'D-Day';
    if (leftDays <= 30 && leftDays >= 0) return `D-${leftDays}`;
    return null;
  }

  private getEndDateTime(closingDateTime: string | null) {
    if (!closingDateTime) return null;
    return DateFormat.format(new Date(closingDateTime), 'yyyy.MM.dd HH:mm');
  }
}
