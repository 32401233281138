import { MatchingOfferStatus, PositionDetailStateDto } from '@domain/rs/position/jd/PositionDetailStateDto';
import { JobApplicationProcessType } from '@domain/constant/jobApplicationProcessType';
import DateUtil from '@utils/date/util';

export class PositionDetailStateVO {
  bookmarkYn: boolean;
  jobApplicationProcessType: JobApplicationProcessType | null;
  matchingOfferStatus: MatchingOfferStatus | null;
  decisionDueDateTime: string | null;
  isInDecisionDueDate: boolean;

  constructor(dto: PositionDetailStateDto) {
    this.bookmarkYn = dto.bookmarkYn;
    this.jobApplicationProcessType = dto.jobApplicationProcessType;
    this.matchingOfferStatus = dto.matchingOfferStatus;
    this.decisionDueDateTime = dto.decisionDueDateTime;
    this.isInDecisionDueDate =
      this.matchingOfferStatus === 'OFFER' &&
      this.decisionDueDateTime !== null &&
      DateUtil.isAfterDate(new Date(this.decisionDueDateTime)); // dueDate가 남았는지 확인
  }
}
