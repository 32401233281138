import { PositionExternalDetailDto } from '@domain/rs/position/jd/PositionExternalDetailDto';
import DateFormat from '@utils/date/format';
import DateUtil from '@utils/date/util';

export class PositionExternalDetailVO {
  url: string | null;
  isStart: boolean;
  startDateTime: string;

  constructor(dto: PositionExternalDetailDto) {
    this.url = dto.url;
    this.isStart = DateUtil.compare(DateUtil.getNow(), new Date(dto.startDateTime)) >= 0;
    this.startDateTime = this.formatStartDateTime(dto.startDateTime);
  }

  private formatStartDateTime(createdDate: string) {
    return DateFormat.format(new Date(createdDate), 'MM.dd(week) HH:mm OPEN');
  }
}
