import { PositionMetadataDto } from '@domain/rs/position/jd/PositionMetadataRs';
import { RecruitCategoryTypeNameMap } from '@domain/constant/recruitCategoryType';
import DateFormat from '@utils/date/format';

export class PositionMetadataVO {
  positionSn: number;
  companyName: string;
  positionTitle: string;
  jobGroup: string;
  jobTitle: string;
  recruitmentTypeText: string;
  closingDateTime: string;
  thumbnailImageUrl: string;

  constructor(dto: PositionMetadataDto, sn: number) {
    this.positionSn = sn;
    this.companyName = dto.companyName;
    this.positionTitle = dto.positionTitle;
    this.jobGroup = dto.jobGroup ?? '';
    this.jobTitle = dto.jobTitle ?? '';
    this.recruitmentTypeText = RecruitCategoryTypeNameMap[dto.recruitmentType];
    this.closingDateTime = this.formatCreatedDateTime(dto.closingDateTime);
    this.thumbnailImageUrl = this.getThumbnailImageUrl(dto.thumbnailImageUrl);
  }

  private formatCreatedDateTime(closingDateTime: string | null) {
    if (!closingDateTime) return '';
    return DateFormat.format(new Date(closingDateTime), 'yyyy.MM.dd(week) HH:mm');
  }

  private getThumbnailImageUrl(thumbnailImageUrl: string | null) {
    if (!thumbnailImageUrl) return '';
    return `${thumbnailImageUrl}?w=800&h=400`;
  }
}
