import { getChallengeStatus } from '../../challenge/MatchingChallengeListVO';
import {
  PositionDetailAdditionalDto,
  PositionDetailChallengeDto,
  WelfareCategoryWithWelfareNameDto,
} from '@domain/rs/position/jd/PositionDetailAdditionalDto';
import { LocationCodeNumberType } from '@domain/constant/locationType';
import { WelfareCategoryType } from '@domain/constant/welfareCategoryType';
import { ChallengeStatusType } from '@domain/constant/challenge/challengeStatusType';

export class WelfareCategoryWithWelfareNameVO {
  name: WelfareCategoryType;
  welfares: string[];

  constructor(dto: WelfareCategoryWithWelfareNameDto) {
    this.name = dto.name;
    this.welfares = dto.welfares || [];
  }
}

export class PositionDetailChallengeVO {
  challengeSn: number;
  challengeName: string;
  applicableSize: number | null;
  postStartDateTime: string;
  postEndDateTime: string;
  endYn: boolean;
  challengeStatus: ChallengeStatusType;

  constructor(dto: PositionDetailChallengeDto) {
    this.challengeSn = dto.challengeSn;
    this.challengeName = dto.challengeName;
    this.applicableSize = dto.applicableSize;
    this.postStartDateTime = dto.postStartDateTime;
    this.postEndDateTime = dto.postEndDateTime;
    this.endYn = dto.endYn;
    this.challengeStatus = getChallengeStatus(dto.postStartDateTime, dto.postEndDateTime, dto.endYn);
  }
}
export class PositionDetailAdditionalVO {
  locationCode: LocationCodeNumberType;
  jobGroupName: string;
  jobGroupCode: number;
  jobTitleNames: string[];
  welfareKeywords: string[];
  welfareWithCategories: WelfareCategoryWithWelfareNameVO[];
  challenge: PositionDetailChallengeVO | null;

  constructor(dto: PositionDetailAdditionalDto) {
    this.locationCode = dto.locationCode;
    this.jobGroupName = dto.jobGroupName;
    this.jobGroupCode = dto.jobGroupCode;
    this.jobTitleNames = dto.jobTitleNames || [];
    this.welfareKeywords = dto.welfareKeywords || [];
    this.welfareWithCategories =
      dto.welfareWithCategories?.map((category) => new WelfareCategoryWithWelfareNameVO(category)) || [];
    this.challenge = dto.challenge ? new PositionDetailChallengeVO(dto.challenge) : null;
  }
}
