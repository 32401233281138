import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { PositionMetadataVO } from '@domain/vo/common/PositionMetadataVO';
import { RemotePositionRepo } from '@repository/position/PositionRepo';
import PositionJobGroupsRq from '@domain/rq/position/PositionJobGroupsRq';
import PositionProfileRecommendRq from '@domain/rq/index/PositionProfileMatchingRq';
import { PositionDetailVO } from '@domain/vo/position/jd/PositionDetailVO';
import { PositionCompanyCardListVO } from '@domain/vo/position/jd/PositionCompanyCardListVO';
import { PositionJobListCardVO } from '@domain/vo/position/jd/PositionJobListCardVO';
import { PositionChallengeCardListVO } from '@domain/vo/position/jd/PositionChallengeCardListVO';
import { PositionBookmarkRs } from '@domain/rs/position/jd/PositionBookmarkRs';

const keys = ['position'];

const repo = new RemotePositionRepo();

// 직무 기반 맞춤 포지션 조회
export const fetchPositionProfileRecommend = async (rq: PositionProfileRecommendRq) => {
  const { data } = await repo.fetchPositionProfileRecommend(rq);
  return data;
};

export const usePositionProfileRecommend = (rq: PositionProfileRecommendRq) => {
  return useQuery([...keys, 'profile', 'recommend', rq], () => fetchPositionProfileRecommend(rq), {
    staleTime: 1000,
  });
};

//직군별 인기 포지션 조회 > 선택 직군 포지션 랜덤 조회
export const fetchPositionJobGroups = async (rq: PositionJobGroupsRq) => {
  const { data } = await repo.fetchPositionJobGroups(rq);
  return data;
};

export function usePositionJobGroups(rq: PositionJobGroupsRq) {
  return useQuery([...keys, 'PositionList', 'JobGroups', rq], () => fetchPositionJobGroups(rq), {
    enabled: !!rq.jobGroupCode,
  });
}

// 매칭 포지션 JD 상세 조회
const fetchJD = async (positionSn: number | null) => {
  if (!positionSn) return;
  const { data } = await repo.fetchJD(positionSn);
  return new PositionDetailVO(data);
};

export const useJD = (positionSn: number | null) => {
  return useQuery([...keys, positionSn], () => fetchJD(positionSn), {
    enabled: !!positionSn,
    refetchOnWindowFocus: true,
  });
};

// 매칭 포지션 JD 미리보기 조회
export const useJDPreview = (publicKey: string, positionId: string) => {
  return useQuery(
    [...keys, 'preview', publicKey, positionId],
    async () => {
      const { data } = await repo.fetchJDPreview(publicKey, positionId);
      return new PositionDetailVO(data);
    },
    {
      enabled: !!publicKey && !!positionId,
    },
  );
};

// 동일 기업 포지션 조회
export const useMatchingCompany = (positionSn: number) => {
  return useQuery(
    [...keys, 'matchingCompany', positionSn],
    async () => {
      const { data } = await repo.fetchMatchingCompany(positionSn);
      return new PositionCompanyCardListVO(data);
    },
    {
      enabled: !!positionSn,
    },
  );
};

// 동일 직군 포지션 랜덤 조회
export const useMatchingJob = (positionSn: number, pageSize: number) => {
  return useQuery(
    [...keys, 'matchingJob', positionSn, pageSize],
    async () => {
      const { data } = await repo.fetchMatchingJob(positionSn, pageSize);
      return new PositionJobListCardVO(data);
    },
    {
      enabled: !!positionSn,
    },
  );
};

// 동일 챌린지 포지션 조회
export const useMatchingChallenge = (positionSn: number) => {
  return useQuery(
    [...keys, 'matchingChallenge', positionSn],
    async () => {
      const { data } = await repo.fetchMatchingChallenge(positionSn);
      return new PositionChallengeCardListVO(data);
    },
    { enabled: !!positionSn },
  );
};

// 포지션 북마크 삭제
export const useDeleteBookmark = (onSuccess?: () => void) => {
  return useMutation((positionSn: number) => repo.fetchDeleteBookmark(positionSn), {
    onSuccess: onSuccess,
  });
};

// 포지션 북마크 추가
export const useAddBookmark = (onSuccess?: (data: AxiosResponse<PositionBookmarkRs>) => void) => {
  return useMutation((positionSn: number) => repo.fetchAddBookmark(positionSn), {
    onSuccess: (data) => onSuccess?.(data),
  });
};

export const fetchPositionSn = async (publicKey: string, positionId: number) => {
  const { data } = await repo.fetchPositionSn(publicKey, positionId);
  return data;
};

//포지션 상세 URL 조회(빌더)
export function useBuilderPositionSn(publicKey: string, positionId: number) {
  return useQuery([...keys, 'builder', publicKey, positionId], () => fetchPositionSn(publicKey, positionId), {
    enabled: !!publicKey && !!positionId,
  });
}

export const fetchPositionMetadata = async (positionSn: number) => {
  const { data } = await repo.fetchPositionMetadata(positionSn);
  return new PositionMetadataVO(data, positionSn);
};

export function usePositionMetadata(positionSn: number) {
  return useQuery([...keys, 'metadata', positionSn], () => fetchPositionMetadata(positionSn), {
    keepPreviousData: true,
  });
}
