import PositionJobPostingVO from '@domain/vo/common/PositionJobPostingVO';
import { PositionExternalDetailVO } from './PositionExternalDetailVO';
import { PositionDetailAdditionalVO } from '@domain/vo/position/jd/PositionDetailAdditionalVO';
import { PositionDetailBasicVO } from '@domain/vo/position/jd/PositionDetailBasicVO';
import { PositionDetailCompanyVO } from '@domain/vo/position/jd/PositionDetailCompanyVO';
import { PositionDetailStateVO } from '@domain/vo/position/jd/PositionDetailStateVO';
import { PositionDetailRs } from '@domain/rs/position/jd/PositionDetailRs';

export class PositionDetailVO {
  basicInfo: PositionDetailBasicVO;
  additionalInfo: PositionDetailAdditionalVO;
  companyInfo: PositionDetailCompanyVO;
  stateInfo?: PositionDetailStateVO;
  externalInfo?: PositionExternalDetailVO;
  jobPosting?: PositionJobPostingVO;

  constructor(rs: PositionDetailRs) {
    this.basicInfo = new PositionDetailBasicVO(rs.basicInfo);
    this.additionalInfo = new PositionDetailAdditionalVO(rs.additionalInfo);
    this.companyInfo = new PositionDetailCompanyVO(rs.companyInfo);

    if (rs.externalInfo) {
      this.externalInfo = new PositionExternalDetailVO(rs.externalInfo);
    }

    if (rs.stateInfo) {
      this.stateInfo = new PositionDetailStateVO(rs.stateInfo);
    }

    if (rs.jobPosting) {
      this.jobPosting = new PositionJobPostingVO(rs.jobPosting);
    }
  }
}
